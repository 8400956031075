<template>
    <div>
        <div class="nav navbar navbar-expand navbar-white navbar-scroll navbar-light border-bottom p-0">
            <div class="nav-item dropdown">
                <a class="nav-link bg-danger dropdown-toggle" data-toggle="dropdown" href="#" role="button"
                    aria-haspopup="true" aria-expanded="false">Cerrar</a>
                <div class="dropdown-menu mt-0">
                    <a class="dropdown-item" href="#" data-widget="iframe-close" data-type="all"
                        @click.prevent="cerrarTodo">Cerrar Todo</a>
                    <!-- <a class="dropdown-item" href="#" data-widget="iframe-close" data-type="all-other" @click.prevent="cerrarResto">Cerrar Las Otras</a>-->
                </div>
            </div>&nbsp;&nbsp;&nbsp;
            <div class="nav-item dropdown">
                <a class="nav-link bg-primary dropdown-toggle" data-toggle="dropdown" href="#" role="button"
                    aria-haspopup="true" aria-expanded="false">Filtro: {{seccionactiva}}</a>
                <div class="dropdown-menu mt-0">
                    <a class="dropdown-item" href="#" data-widget="iframe-close" data-type="all"
                        @click.prevent="ventanasActivas('Todo');seccionactivaaux='Todo'">Todo</a>
                    <a v-for="seccion in secciones" :key="seccion" class="dropdown-item" href="#"
                        data-widget="iframe-close" data-type="all"
                        @click.prevent="ventanasActivas(seccion);seccionactivaaux=seccion">{{seccion.charAt(0).toUpperCase()}}{{seccion.slice(1)}}</a>
                </div>
            </div>&nbsp;&nbsp;&nbsp;
            <!--<a class="nav-link bg-light" href="#" data-widget="iframe-scrollleft" @click.prevent="pasarVentana(-1)">
                <i class="fas fa-angle-double-left"></i>-->
            <ul class="navbar-nav  " style="overflow:scroll" role="tablist">
                <tab-ventana draggable @dragstart="startDrag($event,ruta)" @drop="onDrop($event, ruta)" @dragover.prevent @dragenter.prevent v-for="ruta of ventanas" :key="ruta.fullPath" :ruta="ruta"
                    @rutaSeleccionada="seleccionarRuta" @ventanaCerrar="cerrarVentana" :title="ruta.alt">
                </tab-ventana>
            </ul>
            <!--<a class="nav-link bg-light" href="#" data-widget="iframe-scrollright" @click.prevent="pasarVentana(1)">
                <i class="fas fa-angle-double-right"></i>
            </a>
            <a class="nav-link bg-light" href="#" data-widget="iframe-fullscreen"><i class="fas fa-expand"></i></a>-->
        </div>
        <div>
            <div class="tab-content">
                <div class="tab-empty" style="height: 792px" :style="{ display: !hayVentanas ? 'block' : 'none' }">
                    <h2 class="display-4">No hay ninguna pestaña seleccionada</h2>
                </div>
                <div class="tab-loading" style="height: 792px; display: none;">
                    <div>
                        <h2 class="display-4">Tab is loading <i class="fa fa-sync fa-spin"></i></h2>
                    </div>
                </div>
                <div class="tab-pane" id="panel-pages-charts-inline-html" role="tabpanel"
                    aria-labelledby="tab-pages-charts-inline-html" style="display:block">
                    <!-- #########  Inyección del router-view por slot ######### -->
                    <slot />
                    <!-- #########  FIN slot #########-->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TabVentana from './TabVentana.vue';
import { PwgsApi } from '../../services/PwgsApi';
export default {
    components: {
        TabVentana,
    },
    data() {
        return {
            ventanas: [],
            alertacerrar: '',
            secciones: [],
            seccionactiva: "Todo",
            auxsecciones:[],
            auxventanas: [],
            seccionactivaaux: '',
            objetoamover:'',
        }
    },
    computed: {
        hayVentanas() {
            return this.numVentanas;
        },
        numVentanas() {
            return this.$store.getters.numVentanas;
        },
      
        
    },
    watch: {
        numVentanas() {
            if(this.ventanas.length>1){
            this.ventanas = this.$store.getters.ventanas;
            this.auxventanas = this.$store.getters.ventanas;
            //let aux = this.ventanas[0];
            //this.ventanas.shift();
            //this.ventanas.sort((a, b) => a.fullPath.localeCompare(b.fullPath));
            //this.ventanas.unshift(aux);
            //this.ventanasActivas(this.seccionactiva.toLowerCase());
            }
        },
     $route() {
         this.seccionesVentanas();
         
         let auxruta = this.$route.fullPath.split('/');
  
         //this.ventanasActivas(this.seccionactivaaux);

         if (this.seccionactivaaux.length != 0) {
             if (this.seccionactivaaux != "Todo"){
                this.ventanasActivas(this.seccionactivaaux);
             }
            
         }
         else {
             this.seccionactivaaux = "Todo";
             this.ventanasActivas(this.seccionactivaaux);

         }
         if (this.seccionactiva.toLowerCase() != auxruta[1]) {
             this.seccionactivaaux = "Todo";
             this.ventanasActivas(this.seccionactivaaux);
         }

        }
   
    },
    methods: {
        mostrarInicio() {
            this.$router.push({name: 'Escritorio'});
        },
        array_move(arr, old_index, new_index) {
             if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr; // for testing
},

        startDrag(event, item) {
            
            this.objetoamover = item;
        },
        onDrop(event, item) {
           
           
            this.array_move(this.ventanas, this.ventanas.indexOf(this.objetoamover), this.ventanas.indexOf(item));
            if(this.seccionactiva=="Todo"){
            this.$store.dispatch('guardarVentanas', this.ventanas);
            }
        },
        ventanasActivas(seccion) {
            this.ventanas = this.auxventanas;
            localStorage.setItem("ventanas", this.auxventanas);
            this.seccionactiva = seccion.charAt(0).toUpperCase() + seccion.slice(1);
            if (seccion != "Todo" || seccion != "escritorio") {
                console.log("ventana123",this.ventanas);
                this.ventanas = this.ventanas.filter((ventana) => ventana.fullPath.includes(seccion));
                if (this.ventanas.length == 0) {
                    this.ventanas = this.auxventanas;
                    for (let i = 0; i < this.secciones.length; i++){
                        if (this.secciones[i] == seccion) {
                            this.secciones.splice(i, 1);
                        }
                    }
                    this.seccionactiva = "Todo";

                }
            }
            else {
                this.ventanas = localStorage.getItem("ventanas");
            }
            
        },
        seccionesVentanas() {
            let aux = '';
            this.auxsecciones = this.secciones;
            this.secciones = [];
            this.ventanas = this.$store.getters.ventanas;
            console.log("ventanas", this.ventanas);
            if(this.ventanas.length>1){
            for (let i = 0; i < this.ventanas.length; i++) {
                if (this.ventanas[i].name != "Escritorio" && this.ventanas[i].fullPath) {
                    aux = this.ventanas[i].fullPath.split('/');
                    this.secciones.push(aux[1]);
                }
             
            }
            }
            this.secciones = [...new Set(this.secciones)];

        },/**Mañana*/
        seleccionarRuta(ruta) {
            this.$router.push({
                path: ruta.fullPath
            });
        },
        sort(a, b) {
            return a.DepartmentName.localeCompare(b.DepartmentName);
        },
        /**
         * Al cerrar la ventana de la pestaña cerrada, lo que hacemos es quitar su ruta de la lista de rutas/ventanas
         * Por defecto, debemos dejar otra existente. La última, caso de haber más.
         */
        cerrarVentana(ruta) {
            if (ruta.path.startsWith('/servicios/')) {
                let idruta = ruta.path.split('/', 3)[2];
                const permitir = localStorage.getItem('permitir' + idruta);
                const permitirtipodanyo = localStorage.getItem('permitirtipodanyo' + idruta);
                const permitirfechaespera = localStorage.getItem('permitirfechaespera' + idruta);
                let portramitar = localStorage.getItem('tramitado' + idruta);
                console.log("portramitar", portramitar);
                console.log(permitir, permitirtipodanyo, permitirfechaespera, idruta);
                if (portramitar == "true") {
                        this.$store.dispatch('quitarRuta', ruta);
                        this.cerrarservicio(idruta);

                        if (this.numVentanas > 0) {
                            this.seleccionarRuta(this.$store.getters.ultimaRuta);

                        }
                        else {
                            this.mostrarInicio();
                        }
                    }
                else{
                if ((permitir == "false" || permitirtipodanyo == "false" || permitirfechaespera == "false")) {
                    if(permitir=="false"){
                        alert('Debe cambiar el estado pendiente antes de cerrar el servicio');
                    }
                    if (permitirtipodanyo == "false") {
                        alert('Debe cambiar el tipo de daño antes de cerrar el servicio');
                    }
                    if (permitirfechaespera == "false") {
                        alert('Debe cambiar la fecha de espera antes de cerrar el servicio');
                    }
                    
                }
                else {
                    this.$store.dispatch('quitarRuta', ruta);
                    this.cerrarservicio(idruta);
                    
                    if (this.numVentanas > 0) {
                        this.seleccionarRuta(this.$store.getters.ultimaRuta);

                    }
                    else {
                        this.mostrarInicio();
                    }
                }
                    }
            }
            else {
              
                this.$store.dispatch('quitarRuta', ruta);
                if (this.numVentanas > 0) {
                    this.seleccionarRuta(this.$store.getters.ultimaRuta);

                }
                else {
                    this.mostrarInicio();
                }
            }
            this.ventanasActivas(this.seccionactiva.toLowerCase());

        },
        cerrarTodo() {

            this.$store.dispatch('cerrarTodo');
            this.mostrarInicio();
        },
        cerrarResto() {

            this.$store.dispatch('cerrarResto', this.$route);
        },
        async cerrarservicio(idruta) {
            const api = new PwgsApi();
            await api.put('servicios/' + idruta + '/cerrar');
            localStorage.removeItem("pestanyactivaservicio" + idruta);
        }
    /*    pasarVentana(dir) {
            let indice = this.ventanas.findIndex(rutaActual => rutaActual.fullPath == this.$route.fullPath);
            if (indice === false) {
                return;
            }
            if (dir > 0) {
                if (indice < this.ventanas.length - 1) {
                    indice++;
                }
                else {
                    indice = 0;
                }
            }
            else if (dir < 0) {
                if (indice > 0) {
                    indice--;
                }
                else {
                    indice = this.ventanas.length - 1;
                }
            }
            this.seleccionarRuta(this.ventanas[indice]);
        },*/

    },
    beforeMount() {
        this.ventanas = this.$store.getters.ventanas;
        this.auxventanas = this.$store.getters.ventanas;

        this.seccionesVentanas();

    },
    

       

    
}
</script>
<style scoped>
.navbar {   flex-flow: row nowrap;
    justify-content: flex-start;
}
.navbar-nav {
    width: 100%;
}
</style>